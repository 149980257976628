body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

a {
  text-decoration: none;
}

ul ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* PIXELCODE THEME */
:root {
  font-size: 12px;
  --primary-color: hsl(207, 52%, 39%);
  --secondary-color: hsl(48, 94%, 58%);
  --default-text-color: hsl(200, 10%, 10%);
  --default-padding: 1rem;
  --default-margin: 1rem;
  --default-font-size: 1.5rem;
  --default-font-size-lg: 2rem;
  --default-animation-speed: 500ms;
  --default-border-radius: 15px;

  --nav-height: 6rem;
  --footer-height: 190px;
}

.padded-content {
  min-height: calc(100vh - var(--nav-height) - var(--footer-height));
  padding: calc(var(--default-padding) * 4) 0;
}
